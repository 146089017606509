import React, { useEffect } from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import { DropdownInputOption, DropdownOption } from './Option';

import { IconInput, Props as IconInputProps } from '../Input/IconInput';
import { Props as InputProps } from '../Input/index';

export type Props<T> = Omit<React.HTMLProps<HTMLDivElement>, 'onChange' | 'value' | 'label'> & {
  value?: T;
  options: DropdownOption<T>[];
  id: string;
  onChange: (newValue: T | undefined, selectedOption?: DropdownOption<T>) => void;
  label?: string | JSX.Element;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  optionsContainerClassName?: string;
  optionClassName?: string;
  placeholder?: string;
  helperText?: string | React.ReactElement;
  error?: string;
  disabled?: boolean;
  onFilterFn?: (filter?: string | undefined) => (option: DropdownOption<T>) => boolean;
  defaultOption?: DropdownOption<T>;
  InputComponent?: React.ComponentType<InputProps | IconInputProps>;
  disableInput?: boolean;
  autoFocus?: boolean;
  onSearchChange?: (searchText: string) => void;
  small?: boolean;
  defaultOpen?: boolean;
};

const genericLowerCaseFilter = <T,>(
  callBack: (option: DropdownOption<T>, filter: string) => boolean,
  filter?: string,
): ((option: DropdownOption<T>) => boolean) => {
  const lowerCasedFilter = filter?.toLocaleLowerCase();
  return (option: DropdownOption<T>) => !lowerCasedFilter || callBack(option, lowerCasedFilter);
};

export const filterIncludesNonCaseSensitive = <T,>(
  filter?: string,
): ((option: DropdownOption<T>) => boolean) =>
  genericLowerCaseFilter(
    (option, lowerCasedFilter) => option.label.toLocaleLowerCase().includes(lowerCasedFilter),
    filter,
  );

export function Dropdown<T>({
  id,
  options,
  value,
  onChange,
  label,
  placeholder,
  helperText,
  error,
  onFilterFn,
  disabled,
  containerClassName,
  className,
  inputClassName,
  optionsContainerClassName,
  optionClassName,
  defaultOption,
  InputComponent = Input,
  disableInput = false,
  autoFocus,
  onSearchChange,
  small = false,
  defaultOpen,
  ...rest
}: Props<T>): JSX.Element {
  const [searchText, setSearchText] = React.useState<string | undefined>();
  const [selectedOption, setSelectedOption] = React.useState<DropdownOption<T> | undefined>(
    defaultOption,
  );
  const [isOpen, setIsOpen] = React.useState(defaultOpen ?? false);
  const dropDownRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropDownRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const onFilterChanged = (newValue: string): void => {
    setSearchText(newValue);
    setSelectedOption(undefined);
    setIsOpen(true);
    onChange(undefined, undefined);
    onSearchChange?.(newValue);
  };

  const onClickOption = (newOption: DropdownOption<T>): void => {
    setSelectedOption(newOption);
    setIsOpen(false);
    setSearchText(undefined);
    onChange(newOption.value, newOption);
  };

  useEffect(() => {
    if (value === '') {
      setSelectedOption(undefined);
    }
    const found = options?.find(option => option.value === value);
    if (found && found.value !== selectedOption?.value) {
      setSelectedOption(found);
      setSearchText(undefined);
    }
  }, [selectedOption, options, value, defaultOption]);

  const filterWithDefault = React.useCallback(
    onFilterFn ? onFilterFn : filterIncludesNonCaseSensitive,
    [onFilterFn],
  );
  const renderedOptions = React.useMemo(
    () =>
      options.filter(filterWithDefault(searchText)).length > 0 ? (
        options
          .filter(filterWithDefault(searchText))
          .map((option: DropdownOption<T>) => (
            <DropdownInputOption
              key={`${option.label}${option.value}`}
              option={option}
              onOptionClick={onClickOption}
              selected={option === selectedOption}
              small={small}
            />
          ))
      ) : (
        <DropdownInputOption
          option={{
            label: 'Pas de données disponibles',
            value: '',
            icon: () => <InfoCircleOutline />,
          }}
          onOptionClick={() => {}}
          selected={false}
          small={small}
          className="!text-neutral-600"
        />
      ),
    [options, selectedOption, filterWithDefault, searchText, onClickOption],
  );

  const additionalProps =
    InputComponent === IconInput
      ? {
          IconValue: selectedOption?.icon,
        }
      : {};

  return (
    <div
      onClick={() => !disabled && setIsOpen(true)}
      ref={dropDownRef}
      {...rest}
      className={classNames(containerClassName || className, !disabled && 'cursor-pointer')}
    >
      <InputComponent
        id={id}
        data-testid={id}
        value={searchText || selectedOption?.label || ''}
        onChange={disableInput ? () => {} : onFilterChanged}
        label={small ? '' : label}
        placeholder={placeholder}
        error={!isOpen ? error : undefined}
        helperText={!isOpen ? helperText : undefined}
        suffix={
          isOpen ? (
            <ChevronUp
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
          ) : (
            <ChevronDown />
          )
        }
        disabled={disabled}
        className={classNames(inputClassName || '', {
          'caret-transparent': disableInput,
        })}
        renderingOptions={{
          inputClassName: disableInput ? 'cursor-pointer' : '',
        }}
        data-form-type="other"
        autoFocus={autoFocus}
        dense={small ? 'sm' : undefined}
        {...additionalProps}
      />
      {isOpen && (
        <>
          <div onClick={e => e.stopPropagation()} className="relative z-30 shadow-ds-xs">
            <Card
              className={classNames(
                optionsContainerClassName,
                'absolute w-full mt-xxs border !rounded',
              )}
              bodyClassNames="px-0 py-xs"
            >
              <div className="max-h-[12rem] overflow-auto">{renderedOptions}</div>
            </Card>
          </div>
          {/* Avoids the layout shifting */}
          {error ? <div className="h-md mt-xxs" /> : null}
          {helperText ? <div className="h-lg" /> : null}
        </>
      )}
    </div>
  );
}
