import React from 'react';

import { v4 as uuidV4 } from 'uuid';

import { useFileDropzone } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/hook';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';

import { useRemoveProjectDocument } from 'features/EspaceClient3/features/Documents/api/useRemoveProjectDocument';
import { useUploadProjectDocument } from 'features/EspaceClient3/features/Documents/api/useUploadProjectDocument';
import { ProjectDocument } from 'features/EspaceClient3/features/Documents/types';
import { DocumentsTracking } from 'utils/tracking/DocumentsTracking';

import { useOpenSkipDocumentModal } from '../hooks/useOpenSkipDocumentModal';
import { documentActions } from '../utils/constants';

type Props = {
  projectUuid: string;
  files: ProjectDocument[];
  tag: DocumentTag;
  handleCloseModal: () => void;
  description?: string | React.ReactNode;
};

export const UploadProjectFiles: React.FC<Props> = ({
  projectUuid,
  files,
  tag,
  handleCloseModal,
  description,
}) => {
  const { uploadFiles, isLoading: isUploadingFiles } = useUploadProjectDocument();
  const { removeFile, isLoading: isRemovingFiles } = useRemoveProjectDocument();
  const [FileDropzone, onUpdate, onSuccess, onError] = useFileDropzone();
  const startUpload = async (files: File[]): Promise<{ files: File[] }> => {
    const filesWithMetadata = files.map(file => ({
      uuid: uuidV4(),
      name: file.name,
      type: file.type,
      body: file,
    }));

    const result = await uploadFiles({
      projectUuid,
      tag,
      files: filesWithMetadata,
      onUpdate,
      onSuccess,
      onError,
    });

    DocumentsTracking.onDocumentUploaded({ 'Project Uuid': projectUuid });

    return {
      files: result,
    };
  };
  const openSkipDocumentModal = useOpenSkipDocumentModal();
  const actionToSkip = documentActions[tag];

  return (
    <ModalContent
      validateAction={{
        label: 'Ajouter le document',
        onClick: handleCloseModal,
        disabled: isRemovingFiles || isUploadingFiles,
        loading: isRemovingFiles || isUploadingFiles,
      }}
      cancelAction={{
        label: 'Passer cette étape',
        onClick: () =>
          actionToSkip
            ? openSkipDocumentModal({ projectUuid, actionType: actionToSkip })
            : handleCloseModal(),
        disabled: isRemovingFiles || isUploadingFiles,
        loading: isRemovingFiles || isUploadingFiles,
      }}
    >
      <div className="text-ds-b2 text-neutral-600 mb-md">{description}</div>
      <FileDropzone
        multiple
        handleUploadFile={startUpload}
        files={files}
        onRemoveFile={file => removeFile({ projectUuid, fileUuid: file.uuid })}
        trackingDownloadProps={{
          'data-tracking-group': 'upload_documents',
          'data-tracking-item': 'view_uploaded_file',
        }}
      />
    </ModalContent>
  );
};
