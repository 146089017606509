import { Ligne, DevisLocation } from '@travauxlib/shared/src/types';

import { areLinesSimilar, areLinesIdentical } from './lineComparisons';

import { LigneComparison } from '../types';

export const generateLineComparison = (
  baseLines: Ligne[],
  compareLines: Ligne[],
  baseDevisLocations: DevisLocation[],
  compareDevisLocations: DevisLocation[],
  matchThread: boolean,
): LigneComparison[] => {
  // we don't want a base ligne to match the same compare lines multiple times
  const ignoredUuids: string[] = [];
  const comparison: LigneComparison[] = [];

  // first we find similarities
  baseLines.forEach(baseLine => {
    const lineToCompare = compareLines.find(
      compareLine =>
        areLinesSimilar(baseLine, compareLine, matchThread) &&
        !ignoredUuids.includes(compareLine.uuid),
    );

    if (!lineToCompare) {
      return comparison.push({
        status: 'missing',
        base: baseLine,
      });
    }

    ignoredUuids.push(lineToCompare.uuid);
    comparison.push({
      status: areLinesIdentical(baseLine, lineToCompare, baseDevisLocations, compareDevisLocations)
        ? 'unchanged'
        : 'modified',
      base: baseLine,
      compare: lineToCompare,
    });
  });

  const filteredList = compareLines.filter(
    compareLine => !baseLines.find(baseLine => areLinesSimilar(baseLine, compareLine, matchThread)),
  );

  filteredList.forEach(line =>
    comparison.push({
      status: 'added',
      compare: line,
    }),
  );

  return comparison;
};
