// Common events

export enum TrackingEvents {
  Added = 'Added',
  Archived = 'Archived',
  Clicked = 'Clicked',
  Completed = 'Completed',
  Created = 'Created',
  DevisPersonalized = 'DevisPersonalized',
  Downloaded = 'Downloaded',
  Edited = 'Edited',
  Favourited = 'Favourited',
  IntervalChanged = 'IntervalChanged',
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
  Opened = 'Opened',
  Restored = 'Restored',
  Sent = 'Sent',
  ShortcutUsed = 'ShortcutUsed',
  StatusChanged = 'StatusChanged',
  Stopped = 'Stopped',
  Updated = 'Updated',
  Viewed = 'Viewed',
  Started = 'Started',
  Ended = 'Ended',
  Deleted = 'Deleted',
  Validated = 'Validated',
}

// App tracking

export enum AppFeatures {
  Documents = 'Documents',
  EspaceClient = 'EspaceClient',
  GuidesClient = 'GuidesClient',
  DevisClient = 'DevisClient',
  AvancementChantier = 'AvancementChantier',
  OffreFinancement = 'OffreFinancement',
}

export enum EspaceClientSubFeatures {
  Home = 'Home',
  Chantiers = 'Chantiers',
  Documents = 'Documents',
  Guides = 'Guides',
  AskForHelp = 'AskForHelp',
  DocumentsExplanation = 'DocumentsExplanation',
  EstimateProjectCTA = 'EstimateProjectCTA',
  EstimateProjectPage = 'EstimateProjectPage',
  InvoicesAndBills = 'InvoicesAndBills',
  Payment = 'Payment',
  ProjectName = 'ProjectName',
  ProjectStep = 'ProjectStep',
  ReferralCTA = 'ReferralCTA',
  ReferralPage = 'ReferralPage',
}

export enum GuidesClientSubFeatures {
  Accompagnements = 'Accompagnements',
  AccompagnementTab = 'AccompagnementTab',
  AchatFournitures = 'AchatFournitures',
  Architectes = 'Architectes',
  ArchitecteTab = 'ArchitecteTab',
  Concept = 'Concept',
  CompteSequestre = 'CompteSequestre',
  DebuterProjetTab = 'DebuterProjetTab',
  Demarrage = 'Demarrage',
  Garanties = 'Garanties',
  Interlocuteurs = 'Interlocuteurs',
  PrixDevis = 'PrixDevis',
  Reception = ' Reception',
  RedirectionLink = 'RedirectionLink',
  ServicesHemea = 'ServicesHemea',
  SuiviChantier = 'SuiviChantier',
  SuivreSonProjetTab = 'SuivreSonProjetTab',
}

export enum DevisClientSubFeatures {
  AfficherLesModifications = 'AfficherLesModifications',
  Signature = 'Signature',
  Comments = 'Comments',
}

export enum OffreFinancementSubFeatures {
  Detail = 'Detail',
  JeSuisInteresse = 'JeSuisInteresse',
}

export enum EspaceClientEventProperties {
  ProjectUuid = 'Project Uuid',
  ProjectName = 'Project Name',
  ProjectPhase = 'Project Phase',
  Empty = 'Empty',
  DevisToken = 'Devis Token',
  DevisPrixTotalTTC = 'Devis Prix Total TTC',
}

export enum GuidesClientEventProperties {
  ParentGuideSection = 'Parent Guide Section',
  PageContainingLink = 'Page Containing Link',
}

export enum DevisClientEventProperties {
  DevisToken = 'Devis Token',
}

// Pro tracking

export enum ClubProFeatures {
  Onboarding = 'Onboarding',
  Registration = 'Registration',
  Identity = 'Identity',
  Devis = 'Devis',
  Facture = 'Facture',
  SuiviDeChantier = 'SuiviDeChantier',
  BonDeCommande = 'BonDeCommande',
  Notification = 'Notification',
  Parrainage = 'Parrainage',
  CentraleAchat = 'CentraleAchat',
  BaseDePrix = 'BaseDePrix',
  ClientChantier = 'ClientChantier',
  Dashboard = 'Dashboard',
  LDD = 'LDD',
  Contact = 'Contact',
  Prestataires = 'Prestataires',
  Parametres = 'Parametres',
  Utilisateurs = 'Utilisateurs',
  NewRegistration = 'NewRegistration',
  DemandeChantier = 'DemandeChantier',
}

export enum BaseDePrixSubFeatures {
  Ligne = 'Ligne',
  MainOeuvre = 'MainOeuvre',
  MateriauxConsommable = 'MateriauxConsommable',
  FournituresEquipements = 'FournituresEquipements',
}

export enum DashboardSubFeatures {
  Devis = 'Devis',
  Facture = 'Facture',
  Graph = 'Graph',
}

export enum DevisSubFeatures {
  Modal = 'Modal',
}

export enum FactureSubFeatures {
  Modal = 'Modal',
}

export enum LDDSubFeatures {
  Ligne = 'Ligne',
  LigneFourniture = 'LigneFourniture',
  TDCLigne = 'TDCLigne',
  Session = 'Session',
  Ouvrage = 'Ouvrage',
}

export enum NotificationSubFeatures {
  All = 'All',
  Tab = 'Tab',
}

export enum OnboardingSubFeatures {
  CentraleAchat = 'CentraleAchat',
  ConfirmationSubscription = 'ConfirmationSubscription',
  Contact = 'Contact',
  Demo = 'Demo',
  Interests = 'Interests',
  Insurance = 'Insurance',
  Software = 'Software',
}

export enum ParametresSubFeatures {
  Utilisateurs = 'Utilisateurs',
  MonEntreprise = 'MonEntreprise',
}

export enum RegistrationSubFeatures {
  Account = 'Account',
  Company = 'Company',
  Interests = 'Interests',
  Siret = 'Siret',
}

// Admin Tracking

export enum AdminFeatures {
  Identity = 'Identity',
  DashboardCDP = 'DashboardCPD',
  InscriptionsPro = 'InscriptionsPro',
  Consultations = 'Consultations',
  Entreprises = 'Entreprises',
  Leads = 'Leads',
  ComptesClients = 'ComptesClients',
  Chantiers = 'Chantiers',
  Partenaires = 'Partenaires',
  Comparateur = 'Comparateur',
  Trombi = 'Trombi',
  Projet = 'Projet',
}
