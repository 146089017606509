import classNames from 'classnames';

import { TagSize, Variant as TagVariant } from './types';

import { IconSizes } from '../Buttons/IconButton';

const tagContainerClassNameBySize: { [key in TagSize]: string } = {
  sm: 'text-ds-xs',
  md: 'text-ds-b2',
  lg: 'text-ds-b1',
};

const makeTagContainerPaddingClassName = ({
  size,
  hasIcon,
  hasClose,
  rightIcon,
  isBorder,
}: {
  isBorder?: boolean;
  size: TagSize;
  hasIcon: boolean;
  hasClose: boolean;
  rightIcon: boolean;
}): string =>
  classNames(
    {
      'leading-[0.875rem]': size === 'sm' && isBorder,
    },
    {
      'py-[1px]': size === 'md' && isBorder,
      'py-[2px]': size === 'md' && !isBorder,
      'py-[3px]': size === 'lg' && isBorder,
      'py-[4px]': size === 'lg' && !isBorder,
    },
    {
      'pr-xs': (size === 'md' && !hasClose) || size === 'lg',
      'pr-xxs': (size === 'md' && hasClose) || (size === 'sm' && !hasClose) || rightIcon,
      'pr-[2px]': size === 'sm' && hasClose,
    },
    {
      'pl-xs': size !== 'sm' && !hasIcon,
      'pl-[2px]': size === 'sm' && hasIcon,
      'pl-xxs': (size !== 'sm' && hasIcon) || (size === 'sm' && !hasIcon),
    },
  );

const tagContainerClassNameByVariant: { [key in TagVariant]: string } = {
  light: 'bg-neutral-200 text-neutral-900',
  info: 'bg-info-100 text-info-900',
  warning: 'bg-warning-100 text-warning-900',
  success: 'bg-success-100 text-success-900',
  error: 'bg-error-100 text-error-900',
  primary: 'bg-primary-400 text-neutral-0',
  beige: 'bg-beige-50 text-primary-400',
};

const borderTagContainerClassNameByVariant: { [key in TagVariant]: string } = {
  light: 'bg-neutral-100 text-neutral-800 border border-neutral-300',
  info: 'bg-info-50 text-info-800 border border-info-300',
  warning: 'bg-warning-50 text-warning-800 border border-warning-300',
  success: 'bg-success-50 text-success-800 border border-success-300',
  error: 'bg-error-50 text-error-800 border border-error-300',
  primary: 'bg-primary-50 text-primary-800 border border-primary-300',
  beige: 'bg-beige-50 text-beige-800 border border-beige-300',
};

export const sizeForIconButton: { [key in TagSize]: IconSizes } = {
  sm: 'xxs',
  md: 'xs',
  lg: 'xl',
};

export const iconContainerSize: { [key in TagSize]: string } = {
  sm: 'w-sm h-sm',
  md: 'w-md h-md',
  lg: 'w-lg h-lg',
};

export const makeTagContainerClassName = ({
  variant,
  size,
  hasIcon,
  hasClose,
  className,
  rightIcon,
  isBorder,
}: {
  variant: TagVariant;
  size: TagSize;
  hasIcon: boolean;
  hasClose: boolean;
  className: string | undefined;
  rightIcon: boolean;
  isBorder?: boolean;
}): string =>
  classNames(
    isBorder ? 'rounded-md' : 'rounded-xxs',
    'inline-flex items-center',
    tagContainerClassNameBySize[size],
    makeTagContainerPaddingClassName({ size, hasIcon, hasClose, rightIcon, isBorder }),
    isBorder
      ? borderTagContainerClassNameByVariant[variant]
      : tagContainerClassNameByVariant[variant],
    className,
  );

export const makeIconContainerClassName = ({ size }: { size: TagSize }): string =>
  classNames('shrink-0 flex justify-center items-center', iconContainerSize[size]);
