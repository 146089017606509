import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

export const initDayjs = (): void => {
  dayjs.locale('fr');
  dayjs.extend(localizedFormat);
  dayjs.extend(customParseFormat);
  dayjs.extend(calendar);
};

export const parseIsoDateTime = (input: string | undefined): Dayjs | undefined =>
  input ? dayjs(input) : undefined;

export const safeFormat = (
  date: Dayjs | string | undefined,
  format: string,
): string | undefined => {
  if (!date) {
    return undefined;
  }
  return dayjs(date).format(format);
};

export const localDateFormat = 'YYYY-MM-DD';

export const formatLocalDate = (date: string | Dayjs | undefined): string | undefined =>
  safeFormat(date, localDateFormat);

export const frenchDateFormat = 'DD/MM/YYYY';

export const formatFullFrenchDateTime = (date: string | Dayjs): string | undefined =>
  safeFormat(date, 'dddd DD MMMM YYYY [à] HH[h]mm');

export const formatFrenchDate = (date: string | Dayjs | undefined): string | undefined =>
  safeFormat(date, 'DD/MM/YYYY');

export const dateTimeFormat = (date: string | Dayjs | undefined): string | undefined =>
  safeFormat(date, 'L [à] LT');

export const shortDateTimeFormat = (date: string | Dayjs | undefined): string | undefined =>
  safeFormat(date, 'DD MMMM [à] LT');

export const parseDateInput = (date: Dayjs | null | undefined): null | string | undefined => {
  if (!date || !date.isValid()) {
    return null;
  }
  return formatLocalDate(date);
};

const MAX_NB_OF_DAYS = 20;

const calendarDateFormat = {
  sameDay: "[aujourd'hui]",
  lastDay: '[hier]',
  nextDay: '[demain]',
  lastWeek(this: Dayjs) {
    return dayjs().startOf('day').diff(this, 'day') <= 2
      ? this.format('[avant-hier]')
      : this.format('dddd [dernier]');
  },
  nextWeek(this: Dayjs) {
    return Math.abs(dayjs().startOf('day').diff(this, 'day')) <= 2
      ? this.format('[après-demain]')
      : this.format('dddd [prochain]');
  },
  sameElse(this: Dayjs) {
    const daysDiff = dayjs().startOf('day').diff(this, 'day');

    return daysDiff < 0 && daysDiff > -MAX_NB_OF_DAYS
      ? this.format(`[dans ${Math.abs(daysDiff)} jours]`)
      : this.format('[le] DD/MM/YYYY');
  },
};

export const toCalendarFormat = (input: Dayjs): string =>
  input.calendar(undefined, calendarDateFormat).toLowerCase();

export const toCalendarFormatForDateTime = (input: Dayjs): string => {
  const dayPart = input.calendar(undefined, calendarDateFormat).toLowerCase();
  return `${dayPart} à ${input.format('LT')}`;
};

export const isInFuture = (date?: string): boolean =>
  date !== undefined && dayjs(date).isAfter(dayjs());
